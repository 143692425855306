import { render, staticRenderFns } from "./PartsListExchangeTable.vue?vue&type=template&id=2399fc76&scoped=true&"
import script from "./PartsListExchangeTable.vue?vue&type=script&lang=js&"
export * from "./PartsListExchangeTable.vue?vue&type=script&lang=js&"
import style0 from "./PartsListExchangeTable.vue?vue&type=style&index=0&id=2399fc76&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2399fc76",
  null
  
)

export default component.exports