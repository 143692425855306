<template>
  <div class="client-page flex-column bg-table-header">
    <div class="p-tb-10 p-l-10 border-bottom-1 flex bg-white">
      <span class="cursor-pointer" @click="toBack">
        <i class="el-icon-arrow-left"></i>
        返回
      </span>
      <div class="flex1 text-center font-size-16 font-weight-bold">订单修改</div>
    </div>
    <div class="flex1 p-lr-20 p-tb-20 flex-column overflow-auto">
      <!-- 开单类型 -->
      <div class="m-t-10 bg-white  p-tb-20 p-lr-20 font-size-20 font-weight-bold">
        <div class="flex flex-between">
          <div class="m-b-10">
            <span>订单号：{{ orderInfo.orderCode }}</span>
          </div>
          <div>
            <span class="m-r-20">提交人：{{ orderInfo.submitByName }} </span>
            <span>提交时间：{{ orderInfo.submitAt }}</span>
          </div>
        </div>
        <div>
          <span class="m-r-20" v-show="false">开单类型：代他人开单 </span>
          <span v-if="orderInfo.buyerName">买家名称：{{ orderInfo.buyerName }}</span>
        </div>
      </div>

      <!-- 收货地址 -->
      <Card>
        <template #title>收货地址</template>
        <template #content>
          <div>
            <EditAddress @select="selectAddress" :addressInfo="addressInfo" />
          </div>
        </template>
      </Card>

      <!-- 商品明细 -->
      <Card>
        <template #title>商品明细</template>
        <template #content>
          <OrderDetailItem :table="goodsDetailsTable" :isShow="false">
            <template #footer>
              <div>
                <div class="flex flex-row-center">
                  <span>共{{ goodsDetailsTable.length }}种，{{ orderInfo.totalQty }}件商品</span>
                  <span class="m-l-20"> 合计：{{ finalAmountFn(orderInfo) }} </span>
                </div>
                <div class="flex flex-row-center" v-if="Number(orderInfo.concessionsAmount) > 0">
                  <span class="line-price">
                    原价：{{ Number(orderInfo.finalOriginalPrice) ? toFixedFn(orderInfo.finalOriginalPrice) : 0 }}
                  </span>
                  <span class="m-l-20">已优惠：{{ toFixedFn(orderInfo.concessionsAmount) }}</span>
                </div>
              </div>
            </template>
          </OrderDetailItem>
        </template>
      </Card>
      <!-- 订单信息 -->
      <Card>
        <template #title>订单信息</template>
        <template #content>
          <div class="bg-white orderInfo  flex">
            <div class="border-right-1 flex1">
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">结算方式：</span>
                <span class="text-666">{{ orderInfo.paymentType }}</span>
              </div>
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">发票类型：</span>
                <span class="text-666">{{ orderInfo.invoiceType }}</span>
              </div>
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">提货方式：</span>
                <span class="text-666">{{ orderInfo.deliveryType }}</span>
              </div>
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">运输方式：</span>
                <span class="text-666">{{ orderInfo.transportType }}</span>
              </div>
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">发货门店：</span>
                <span class="text-666">{{ orderInfo.realSellerName }}/{{ orderInfo.realSellerPhone }}</span>
              </div>
              <div class="m-b-10" v-if="orderInfo.splitBy">
                <span class="w-100 font-weight-bold">拆单类型：</span>
                <span class="text-666">{{ orderInfo.splitBy }}</span>
              </div>
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">创建人：</span>
                <span class="text-666">{{ orderInfo.createdByName }}</span>
              </div>
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">创建时间：</span>
                <span class="text-666">{{ orderInfo.createdAt }}</span>
              </div>
            </div>
            <div class="flex1 m-l-20">
              <div class="font-weight-bold">买家备注：</div>
              <el-input
                type="textarea"
                class="m-t-4"
                placeholder="请输入内容"
                v-model="orderInfo.remarks"
                maxlength="200"
                clearable
                show-word-limit
                :autosize="{ minRows: 5, maxRows: 5 }"
              >
              </el-input>
            </div>
          </div>
        </template>
      </Card>

      <div class="m-t-10 bg-white p-tb-20 p-lr-20 flex flex-col-end">
        <div style="width:300px">
          <div class="flex flex-row-center m-b-10">
            <span class="inline-block w-70 text-right m-r-20">商品总价</span>
            <span class="flex1 text-right">{{ toFixedFn(orderInfo.totalAmount) }}</span>
          </div>
          <div v-if="couponInfo && couponInfo.id" class="flex flex-row-center m-b-10">
            <span class="inline-block w-70 text-right m-r-20">优惠券</span>
            <span class="flex1 text-right">
              <span class="font-size-12 coupon-flag">{{ couponInfo.content }}</span>
              <span class="m-l-10 text-danger font-weight-bold">
                {{ `-¥${Number(couponInfo.amount).toFixed(2)}` }}
              </span>
            </span>
          </div>
          <div v-if="pointsData && pointsData.enable" class="flex flex-row-center m-b-10">
            <span class="inline-block w-70 text-right m-r-20">积分抵扣</span>
            <span class="flex1 text-right text-danger" v-if="pointsData.availablePoints">
              本次使用{{ pointsData.availablePoints }}积分抵扣{{ pointsData.availableDeductAmount }}元
            </span>
            <span v-else class="flex1 text-right text-999">暂无可用积分</span>
          </div>
          <div v-if="pointsData && pointsData.enable && pointsData.incomePoints" class="flex flex-row-center m-b-10">
            <span class="inline-block w-70 text-right m-r-20">
              <span class="font-size-12 coupon-flag">积分</span>
            </span>
            <span class="flex1 text-right text-danger">订单完成后积分 +{{ pointsData.incomePoints }}</span>
          </div>
          <div class="flex flex-row-center m-b-10">
            <span class="inline-block w-70 text-right m-r-20">应付金额</span>
            <span class="flex1 text-right">{{ toFixedFn(orderInfo.payAmount) }}</span>
          </div>
          <div class="flex flex-row-center">
            <span class="inline-block w-70 text-right m-r-20">实付金额</span>
            <span class="flex1 text-right font-size-20 font-weight-bold text-danger">
              {{ toFixedFn(orderInfo.paidAmount) }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white p-tb-10 p-lr-10 text-right border-top-1">
      <el-button type="primary" size="small" @click="sureFn">
        确定(F4)
      </el-button>
    </div>
  </div>
</template>

<script>
import Card from "../components/Card.vue";
import EditAddress from "../components/EditAddress.vue";
import OrderDetailItem from "../components/OrderDetailItem.vue";

import { getOrderDetail, updateOrders } from "@/api/order";

export default {
  name: "modifyOrderDetail1", //待发货、待确认 修改订单详情
  components: {
    Card,
    EditAddress,
    OrderDetailItem
  },
  data() {
    return {
      keyName: "f4",
      keyScope: "ModifyOrderDetail1",
      // 订单详情
      orderInfo: {},
      addressInfo: {},
      // 商品明细表
      goodsDetailsTable: [],
      // 优惠券，积分
      couponList: [],
      couponInfo: {},
      pointsData: {
        availableDeductAmount: 0,
        availablePoints: 0,
        points: 0,
        incomePoints: 0,
        enable: true,
        isDetail: false
      }
    };
  },
  mounted() {
    this.getOrderDetailFn();

    setTimeout(() => {
      this.$hotkeys(this.keyName, this.keyScope, this.handleKeyCode);
      this.$hotkeys.setScope(this.keyScope);
    }, 500);
  },
  beforeDestroy() {
    this.$hotkeys.unbind(this.keyName, this.keyScope);
  },
  methods: {
    finalAmountFn(item) {
      return Number(item.finalOriginalPrice) > 0
        ? (Number(item.finalOriginalPrice) - Number(item.concessionsAmount)).toFixed(2)
        : 0;
    },
    handleKeyCode(e) {
      switch (e.code) {
        case "F4":
          e.preventDefault();
          e.stopPropagation();
          this.sureFn();
          break;
      }
    },
    //金额保留两位小数
    toFixedFn(amount) {
      if (amount === "0" || Number(amount) === 0) {
        return "- -";
      }
      return isNaN(Number(amount)) ? "议价" : `￥${Number(amount).toFixed(2)}`;
    },
    //获取订单详情
    getOrderDetailFn() {
      getOrderDetail({ id: this.$route.query.id })
        .then(res => {
          this.orderInfo = res || {};
          this.addressInfo = {
            type: "modify",
            receiver: res.receiver,
            receiverAddress: res.receiverAddress,
            receiverAreaId: res.receiverAreaId,
            receiverPhone: res.receiverPhone,
            receiverAreaName: res.receiverAreaName
          };
          this.goodsDetailsTable =
            res.details.map(item => ({
              ...item,
              amount: (Number(item.qty) * Number(item.price)).toFixed(2)
            })) || [];

          if (res.concessions && res.concessions.coupon) {
            this.couponInfo = res.concessions.coupon;
          }
          this.pointsData = {
            availableDeductAmount: Number(res.concessions?.point?.amount || 0),
            availablePoints: Number(res.concessions?.point?.points || 0),
            points: Number(res.points || 0),
            incomePoints: Number(res.incomePoints || 0),
            enable: res.enable,
            isDetail: true
          };
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    },
    //返回
    toBack() {
      this.$router.go(-1);
    },
    //选择地址
    selectAddress(data) {
      this.orderInfo.addressId = data.id;
    },
    //确定修改
    sureFn() {
      const params = {
        ...this.orderInfo
      };
      updateOrders(params)
        .then(() => {
          this.$message.success("订单修改成功");
          this.$router.go(-1);
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    }
  }
};
</script>

<style lang="less" scoped>
.flag {
  background: #f56c6c;
  color: #ffffff;
  border-radius: 20px;
  padding: 2px 10px;
  font-size: 12px;
}
.address {
  /deep/.el-button--small {
    padding: 8px 0;
  }
  .transform-90 {
    transform: rotate(90deg);
  }
  .noData {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
  }
}
.orderInfo {
  span {
    display: inline-block;
  }
}
.coupon-flag {
  color: #f56c6c;
  padding: 0 4px;
  border-radius: 2px;
  background: rgba(255, 110, 76, 0.08);
  border: 1px solid #e84f47;
}
</style>
