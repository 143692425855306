<template>
  <div class="select-coupon">
    <el-radio-group v-model="radioCouponId">
      <div
        class="coupon-item  m-r-20 m-tb-10"
        :class="[item.id == radioCouponId ? 'coupon-active' : '']"
        v-for="(item, index) in couponList"
        :key="index"
        @click="couponChange(item)"
      >
        <div class="coupon-radio">
          <el-radio :label="item.id"></el-radio>
        </div>
        <div class="coupon-content coupon-bg-color coupon-border-raduis flex flex-row-center h-100">
          <div class="coupon-label text-center coupon-color">{{ item.valueContent }}</div>
          <div class="flex1">
            <div class="font-weight-bold">
              <span class="m-r-20">{{ item.name }}</span>
              <el-tooltip placement="top">
                <div slot="content" class="white-space">{{ item.description }}</div>
                <i class="el-icon-info coupon-color cursor-pointer"></i>
              </el-tooltip>
            </div>
            <div class="font-size-12 m-t-10">{{ item.title }}</div>
            <div class="font-size-12 m-t-10">有效期：{{ `${item.startDate} 至 ${item.endDate}` }}</div>
          </div>
        </div>
      </div>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  name: "CouponSelect",
  props: {
    couponList: {
      type: Array,
      require: true
    },
    couponId: {
      type: Number,
      default: 0
    }
  },
  watch: {
    couponId: {
      handler(val) {
        this.radioCouponId = val;
      },
      immediate: true
    }
  },
  mounted() {
    setTimeout(() => {
      this.$forceUpdate();
    }, 1000);
  },
  data() {
    return {
      radioCouponId: 0
    };
  },

  methods: {
    // 选择优惠券
    couponChange(item) {
      if (this.radioCouponId === item.id) {
        this.radioCouponId = 0;
      } else {
        this.radioCouponId = item.id;
      }
      this.$emit("couponChange", this.radioCouponId);
    }
  }
};
</script>

<style lang="less" scoped>
.select-coupon {
  max-height: 260px;
  overflow: auto;
  /deep/.el-radio-group {
    font-size: 14px;
  }
  .coupon-item {
    min-width: 300px;
    max-width: 320px;
    float: left;
    position: relative;
    border: 1px solid #fff;
    cursor: pointer;
    .coupon-border-raduis {
      border-radius: 12px;
    }
    .coupon-bg-color {
      background: #fef2ef;
    }
    .coupon-color {
      color: #fe7448;
    }
    .coupon-content {
      .coupon-label {
        margin: 0 10px;
        width: 60px;
        font-weight: 650;
        font-style: normal;
        font-size: 20px;
      }
    }
    &.coupon-active {
      border: 1px solid #fe7448;
      border-radius: 12px;
    }
    .coupon-radio {
      position: absolute;
      top: 15px;
      right: 20px;
      /deep/.el-radio__label {
        display: none;
      }
    }
  }
}
</style>
