import request from "@/utils/request";

/**
 * 我的可用优惠券数量
 */
export function getCustomerCouponCount() {
  return request({
    url: "/mall/coupons/customer-coupons/count",
    method: "get"
  });
}

/**
 * 我的优惠券列表
 */
export function getCustomerCouponList(params) {
  return request({
    url: "/mall/coupons/customer-coupons/list",
    method: "get",
    params
  });
}

/**
 * 可领优惠券列表
 */
export function getCustomerCouponCanPickList(params) {
  return request({
    url: "/mall/coupons/customer-coupons/list/can-pick",
    method: "get",
    params
  });
}

/**
 * 领取优惠券
 */
export function pickCustomerCoupon(id) {
  return request({
    url: `/mall/coupons/customer-coupons/pick/${id}`,
    method: "post"
  });
}

/**
 * 我的优惠券详情
 * 客户已领取的优惠券详情
 */
export function getCustomerCouponDetail(id) {
  return request({
    url: `/mall/coupons/customer-coupons/${id}`,
    method: "get"
  });
}

/**
 * 客户查看优惠券详情
 * 客户未领取的优惠券详情
 */
export function getCouponDetail(id) {
  return request({
    url: `/mall/coupons/customer-coupons/detail/${id}`,
    method: "get"
  });
}

/**
 * 客户查看优惠券列表
 */
export function getStoreValidCouponList(params) {
  return request({
    url: "/mall/coupons/customer-coupons/list/list",
    params
  });
}
