<template>
  <div class="coupon-list flex-column height-full p-lr-15 p-b-15">
    <div class="flex1 overflow-auto m-t-10">
      <template v-for="(item, index) in couponList">
        <CouponItem :key="index" :couponInfo="item" :type="couponType" @clickBottom="handleClick">
          <template #btn>
            {{ filterStatus(item) }}
          </template>
        </CouponItem>
      </template>
      <div v-if="!couponList.length" class="flex flex-center text-999 height-full">暂无数据</div>
    </div>
    <div class="text-center m-t-10">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total,  prev, pager, next, jumper, sizes"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import CouponItem from "@/components/Coupon/CouponItem";

import { getCustomerCouponList } from "@/api/coupon";

export default {
  name: "CouponList",
  components: {
    CouponItem
  },
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      couponList: [],
      couponType: "client"
    };
  },
  mounted() {
    this.getCustomerCouponListFn();
  },
  methods: {
    handleClick(item) {
      if (item.status === "normal") {
        const params = {
          path: "/products/browse-products",
          query: {
            couponId: item.id
          }
        };
        this.$router.push(params);
      }
    },
    filterStatus(item) {
      let name = "";
      switch (item.status) {
        case "normal":
          name = "立即使用";
          break;
        case "expired":
          name = "已失效";
          break;
        case "used":
          name = "已使用";
          break;
        case "canceled":
          name = "已作废";
          break;
        default:
          break;
      }
      return name;
    },
    getCustomerCouponListFn() {
      const params = {
        page: this.page,
        pageSize: this.pageSize
      };
      getCustomerCouponList(params)
        .then(res => {
          this.couponList = res.rows || [];
          this.total = res.totalSize || 0;
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getCustomerCouponListFn();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getCustomerCouponListFn();
    }
  }
};
</script>

<style lang="less" scoped>
.coupon-list {
  box-sizing: border-box;
}
</style>
