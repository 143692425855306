<template>
  <div class="coupon-item m-r-20 m-tb-10" :class="{ 'coupon-active': active }">
    <div class="coupon-content coupon-bg-color coupon-border-raduis flex flex-row-center h-100">
      <div class="coupon-label text-center coupon-color">{{ couponInfo.valueContent }}</div>
      <div class="flex1">
        <div class="font-weight-bold">
          <span class="m-r-20">{{ couponInfo.name }}</span>
          <el-tooltip placement="top">
            <div slot="content" class="white-space">{{ couponInfo.description }}</div>
            <i class="el-icon-info coupon-color cursor-pointer"></i>
          </el-tooltip>
        </div>
        <div class="font-size-12 m-t-5">{{ couponInfo.title }}</div>
        <div class="font-size-12 m-t-5">
          <template v-if="type === 'client'">
            有效期：{{ `${couponInfo.startDate} 至 ${couponInfo.endDate}` }}
          </template>
          <template v-else-if="type === 'store'">
            <template v-if="couponInfo.useType === 'date'">
              有效期：{{ `${couponInfo.startDate} 至 ${couponInfo.endDate}` }}
            </template>
            <template v-else-if="couponInfo.useType === 'days'"> 有效期：{{ couponInfo.useContent }} </template>
          </template>
        </div>
      </div>
    </div>
    <div class="coupon-border"></div>
    <div class="coupon-btn coupon-bg-color coupon-border-raduis h-40 flex-column flex-center p-lr-5">
      <el-button size="small" class="width-full" round :disabled="!canEdit" @click="clickBottom">
        <slot name="btn"></slot>
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CouponItem",
  props: {
    // 客户：client  店铺：store
    type: {
      type: String,
      default: "client"
    },
    couponInfo: {
      type: Object,
      require: true
    }
  },
  computed: {
    active() {
      let active = false;
      if (this.type === "store") {
        active = true;
      } else {
        active = this.couponInfo?.status === "normal";
      }
      return active;
    },
    canEdit() {
      return this.active;
    }
  },
  methods: {
    clickBottom() {
      this.$emit("clickBottom", this.couponInfo);
    }
  }
  // data() {
  //   return {
  //     description:
  //       "优惠券名称：甩卖了\n适用商品：全部商品可用\n使用门槛：指定可用商品消费满100元\n优惠内容：减20元\n有效期：领券当日起2天内有效，逾期无效\n领取门槛：全部客户\n特别说明：本券不可兑换现金、不可找零；如果消费并使用优惠券后，取消交易优惠券返还；若发生退款，优惠券不予退还 ！"
  //   };
  // }
};
</script>

<style lang="less" scoped>
.coupon-item {
  min-width: 300px;
  max-width: 320px;
  float: left;

  .coupon-border-raduis {
    border-radius: 12px;
  }
  .coupon-bg-color {
    background: #f4f4f4;
  }
  .coupon-color {
    color: #8e8e8e;
  }
  .coupon-content {
    .coupon-label {
      margin: 0 10px;
      width: 60px;
      font-weight: 650;
      font-style: normal;
      font-size: 20px;
    }
  }
  .coupon-border {
    margin: 0 15px;
    border-bottom: 2px dashed #8e8e8e;
  }
  .coupon-btn {
    box-sizing: border-box;
    .el-button {
      background: #8e8e8e;
      color: #fff;
      border: none;
    }
  }
  &.coupon-active {
    .coupon-bg-color {
      background: #fef2ef;
    }
    .coupon-color {
      color: #fe7448;
    }
    .coupon-border {
      border-bottom: 2px dashed #fe7448;
    }
    .coupon-btn {
      .el-button {
        background: #fe7448;
      }
    }
  }
}
</style>
