<template>
  <div class="top-nav">
    <div class="font-size-16 white-space-nowrap flex flex-row-center">
      <el-image v-if="emblem" class="company-logo" :src="emblem" fit="cover"></el-image>
      <span class="m-l-10">
        {{ storeName }}
      </span>
    </div>
    <el-divider direction="vertical" v-if="false"></el-divider>
    <div class="flex1 overflow-hidden">
      <Notification v-if="false" />
    </div>
    <div class="top-nav-right flex flex-center">
      <div class="top-nav-right-item">
        <el-popover
          @show="mobileEnterShow"
          placement="bottom"
          width="700"
          trigger="click"
          popper-class="popover-contact-mobile-enter"
        >
          <div slot="reference" class="flex flex-center">
            <img :src="phoneIcon" alt="" class="top_nav_logo" />
            <span>手机</span>
          </div>
          <div class="bg-table-header font-weight-bolder font-size-16 p-l-20 p-r-20 p-t-10 p-b-10 text-center">
            手机订货
          </div>
          <div class="p-a-40 flex flex-center mobile-enter-box">
            <div class="flex1 p-t-20">
              <div class="box-shadow p-a-10 offiaccount-code-box">
                <img
                  class="w-140 h-140"
                  :src="offiaccount || siruiOffiaccountIcon"
                  alt="公众号"
                  style="display:inline-block;"
                />
              </div>
              <div class="font-size-12 text-center m-t-10">扫一扫关注公众号</div>
            </div>
            <el-divider class="m-lr-40" direction="vertical"></el-divider>

            <div class="flex1 p-t-20">
              <div class="box-shadow p-a-10 mobile-qrcode-box">
                <div ref="mobileQRCode" class="w-140 h-140 flex flex-center" id="mobileQRCode"></div>
              </div>
              <div class="font-size-12 text-center m-t-10">扫一扫进入移动端</div>
            </div>
            <el-divider class="m-lr-40" direction="vertical"></el-divider>
            <div class="flex1 p-t-20">
              <div class="box-shadow p-a-10 small-program-code-box">
                <img class="w-140 h-140" :src="miniprogram || smallProgramCodeIcon" alt="" />
              </div>
              <div class="font-size-12 text-center m-t-10">扫一扫进入小程序</div>
            </div>
          </div>
        </el-popover>
      </div>
      <div class="top-nav-right-item" @click="toCouponCenter">
        <img :src="couponIcon" alt="" class="top_nav_logo" />
        <span>领劵</span>
      </div>
      <div class="top-nav-right-item">
        <el-popover placement="bottom" width="400" trigger="click" popper-class="popover-contact-the-mall">
          <div slot="reference" class="flex flex-center">
            <img :src="contactMallIcon" alt="" class="top_nav_logo" />
            <span>客服</span>
          </div>
          <div class="bg-table-header font-weight-bolder font-size-16 p-l-20 p-r-20 p-t-10 p-b-10">
            联系商家
          </div>
          <div class="p-a-20">
            <div class="text-center">
              <el-image class="company_img" :src="company.logo || imgDefault" fit="cover"></el-image>
            </div>
            <div class="m-t-10">
              <span class="font-weight-bolder">公司名称：</span>
              <span>{{ company.name }}</span>
            </div>
            <div class="m-t-10">
              <span class="font-weight-bolder">联系地址：</span>
              <span>{{ company.area }} {{ company.address }}</span>
            </div>
            <div class="m-t-10">
              <span class="font-weight-bolder">客服热线：</span>
              <span>{{ company.servicePhone }}</span>
            </div>
          </div>
        </el-popover>
      </div>
      <div v-if="false" class="top-nav-right-item">
        <el-badge is-dot>
          <img :src="chatMsgIcon" alt="" class="top_nav_logo" />
        </el-badge>
        <span>消息</span>
      </div>
      <div v-if="false" class="top-nav-right-item">
        <el-popover placement="bottom" width="400" trigger="click" popper-class="popover-message-notification">
          <div slot="reference" class="flex flex-center">
            <el-badge is-dot>
              <img :src="msgNotificationIcon" alt="" class="top_nav_logo" />
            </el-badge>
            <span>通知</span>
          </div>
          <div class="flex flex-center">
            <span>消息与通知</span>
            <div class="flex1"></div>
            <el-button type="text">一键已读</el-button>
          </div>
          <el-divider></el-divider>
          <div class="message-notification-list">
            <div v-for="item in 10" :key="item">
              <div class="flex flex-center m-t-5">
                <span class="flex1">
                  <el-badge is-dot>业务消息</el-badge>
                </span>
                <span class="font-size-12 text-info">2023-12-04 12:00:00</span>
              </div>
              <div class="m-t-5 m-b-5">
                <el-tag size="mini">商家确认订单成功</el-tag>
              </div>
              <div>
                <span class="font-size-12">
                  商家已确认您的订单，请尽快付款~<br />
                  订单号：135435135843<br />
                  订单金额：￥520.00<br />
                  客户：成都千寻汽配公司-张三-13342541<br />
                  收货信息：张三/17770479608/四川省高新区天府四街长虹科技大厦B座
                </span>
                <el-button type="text" size="mini">【去处理】</el-button>
              </div>
            </div>
          </div>
        </el-popover>
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="top-nav-right-item">
        <div @click="navClick('shoppingCart')" class="cart_box">
          <el-badge :value="shoppingCartNum" :max="99" :hidden="shoppingCartNum === 0">
            <img :src="cartIcon" alt="" class="top_nav_logo" />
          </el-badge>
          <span>购物车</span>
        </div>
      </div>
      <div v-if="!noLoginLook" class="top-nav-right-item">
        <el-popover placement="bottom" width="350" trigger="click" popper-class="popover-personal-center">
          <div slot="reference" class="flex flex-center">
            <div class="flex flex-column">
              <div>Hi {{ userInfo.username }}（{{ userInfo.account }}）</div>
              <div>{{ userInfo.companyName }}</div>
            </div>
            <i class="el-icon-caret-bottom font-size-20"></i>
          </div>
          <div>
            <div class="flex m-b-10">
              <div>
                <el-image class="w-40 h-40 m-t-5" :src="defaultAvatar" fit="cover"></el-image>
              </div>
              <div class="flex1 m-l-10">
                <div class="flex flex-row-center m-b-5">
                  <span class="font-size-16 font-weight-bolder">{{ userInfo.username }}</span>
                  <el-tag v-if="false" type="warning" effect="dark" size="mini" class="m-l-10">思锐</el-tag>
                  <el-button v-if="false" type="text" class="m-l-10 p-a-0">去认证</el-button>
                  <el-button v-if="false" type="text" class="m-l-10 p-a-0">解绑</el-button>
                </div>
                <div class="font-size-12">所属公司：{{ userInfo.companyName }}</div>
                <div v-if="false" class="font-size-12">
                  <span>会员有效期：2025-09-89</span>
                  <el-button type="text" size="mini" class="m-l-10">续费</el-button>
                </div>
              </div>
              <div class="flex flex-center">
                <el-button type="warning" plain size="mini" @click="loginOut">退出登录</el-button>
              </div>
            </div>
            <el-divider></el-divider>
            <div class="flex flex-center">
              <span class="cursor-pointer" @click="changeAccountManage"> 账号管理（{{ accountsGroups.length }}） </span>
              <span class="flex1"></span>
              <el-button type="text" @click="changeAccountManage">切换</el-button>
            </div>
            <el-divider v-if="false"></el-divider>
            <div class="flex flex-center font-size-14 m-t-10" v-if="false">
              <el-card shadow="hover" class="flex1">
                <div class="flex-column flex-center cursor-pointer">
                  <span class="text-warning">2张</span>
                  <span>会员卡</span>
                </div>
              </el-card>
              <el-card shadow="hover" class="flex1 m-l-20">
                <div class="flex-column flex-center cursor-pointer">
                  <span class="text-warning">200</span>
                  <span>积分</span>
                </div>
              </el-card>
              <el-card shadow="hover" class="flex1 m-l-20">
                <div class="flex-column flex-center cursor-pointer">
                  <span class="text-warning">10张</span>
                  <span>优惠劵</span>
                </div>
              </el-card>
            </div>
          </div>
        </el-popover>
      </div>
      <div v-if="!noLoginLook" class="top-nav-right-item">
        <span @click="changeAccountManage">切换</span>
      </div>
      <div v-if="noLoginLook" class="top-nav-right-item">
        <span @click="toLogin">Hi<span class="color-error m-l-10">请先登录</span><span class="m-l-10">！</span></span>
      </div>
    </div>
    <AccountManage ref="accountManage" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import logo from "@/assets/logo.png";
import AccountManage from "../AccountManage";
import Notification from "../Notification";
import { logout } from "@/api/base";
import QRCode from "qrcodejs2";

export default {
  name: "ClientTopNav",
  components: {
    AccountManage,
    Notification
  },
  data() {
    return {
      defaultAvatar: require("@/assets/img/defaultAvatar.png"),
      defaultLogo: require("@/assets/img/default-logo.png"),
      cartIcon: require("@/assets/img/topNav/cart.png"),
      chatMsgIcon: require("@/assets/img/topNav/chatMsg.png"),
      contactMallIcon: require("@/assets/img/topNav/contactMall.png"),
      couponIcon: require("@/assets/img/topNav/coupon.png"),
      phoneIcon: require("@/assets/img/topNav/phone.png"),
      siruiOffiaccountIcon: require("@/assets/img/sirui_offiaccount.png"), // 思锐公众号
      smallProgramCodeIcon: require("@/assets/img/small-program-code.png"),
      msgNotificationIcon: require("@/assets/img/topNav/msgNotification.png"),
      imgDefault: require("@/assets/img/no-goods-img.png"),
      desc: "顶部导航",
      logo: logo,
      mobileQRCode: null
    };
  },
  computed: {
    ...mapState("user", {
      userInfo: state => {
        return state.userInfo;
      },
      shoppingCartNum: state => {
        return state.shoppingCartNum;
      },
      accountsGroups: state => {
        return state.accountsGroups;
      }
    }),
    ...mapState("companyInfo", {
      company: state => {
        return state.company;
      },
      emblem: state => {
        return state.emblem;
      },
      storeName: state => {
        return state.storeName;
      },
      offiaccount: state => {
        return state.company.qrCode.offiaccount;
      },
      miniprogram: state => {
        return state.company.qrCode.miniprogram;
      }
    }),
    noLoginLook() {
      return this.$store.getters["companyInfo/noLoginLook"];
    }
  },
  mounted() {
    if (localStorage.isLogin === "true") {
      // 获取用户信息
      this.$store.dispatch("user/getUserInfo");
      // 获取账号组列表
      this.$store.dispatch("user/getAccountsGroups");
      // 获取购物车列表
      if (!["/products/browse-products", "/products/shopping-cart"].includes(this.$route.path)) {
        this.$store.dispatch("user/getShoppingCartList");
      }
    }
  },
  methods: {
    async navClick(type) {
      if (type === "shoppingCart") {
        if (this.noLoginLook) {
          this.$router.push({
            path: "/login"
          });
          return;
        }
        if (this.userInfo.auditStatus !== "审核通过" && localStorage.isLogin === "true") {
          const data = await this.$store.dispatch("user/getUserInfo");
          if (data && data.auditStatus !== "审核通过") {
            this.$bus.$emit("showAccountStatusDialog");
            return;
          }
        }
        this.$router.push({
          path: "/products/shopping-cart"
        });
      }
    },
    changeAccountManage() {
      this.$refs.accountManage.open();
    },
    loginOut() {
      logout()
        .then(() => {
          // 获取商城资料
          this.$store.dispatch("companyInfo/getCompanyInfo");
          this.$router.push({
            path: "/login"
          });
        })
        .catch(res => {
          this.$message.error(res.message || "退出失败");
        });
    },
    initMobileQRCode() {
      const w = 120;
      this.$refs.mobileQRCode.innerHTML = "";
      this.mobileQRCode = new QRCode(this.$refs.mobileQRCode, {
        width: w,
        height: w,
        text: `https://${window.location.hostname}/h5`
      });
      if (this.emblem) {
        setTimeout(() => {
          this.setMobileQRCodeLogoImg();
        }, 200);
      }
    },
    setMobileQRCodeLogoImg() {
      const w = 120;
      const logoImg = new Image();
      logoImg.setAttribute("crossOrigin", "Anonymous");
      logoImg.src = this.emblem;
      logoImg.onload = () => {
        const qrImg = this.mobileQRCode._el.getElementsByTagName("img")[0];
        const canvas = this.mobileQRCode._el.getElementsByTagName("canvas")[0];
        const ctx = canvas.getContext("2d");
        canvas.style.display = "inline-block";
        // 设置logo的大小为二维码图片缩小的4倍
        ctx.drawImage(logoImg, (w - w / 4) / 2, (w - w / 4) / 2, w / 4, w / 4);
        qrImg.src = canvas.toDataURL();
        qrImg.style.display = "none";
      };
    },
    mobileEnterShow() {
      this.initMobileQRCode();
    },
    toLogin() {
      this.$router.push({
        path: "/login"
      });
    },
    toCouponCenter() {
      this.$router.push({
        path: "/products/coupons-center"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.top-nav {
  color: #666666;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.0980392156862745);
  .company-logo {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
  .top_nav_logo {
    height: 18px;
    margin-right: 10px;
  }
  .top-nav-right {
    margin-left: 100px;
    height: 60px;
  }
  .top-nav-right-item {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    padding: 5px 10px;
    height: 100%;
    box-sizing: border-box;
    &:hover {
      background-color: #e8effd;
    }
  }
  .cart_box {
    padding: 6px 10px;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    background-color: #fcfcfc;
  }
  /deep/.el-badge__content.is-fixed {
    right: 17px;
  }

  @keyframes line {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
}
</style>
<style>
.company_img {
  width: 220px;
  height: 130px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}
</style>
