<template>
  <div class="points-table flex-column height-full p-lr-15 p-b-15">
    <el-table
      v-loading="loading"
      ref="pointsTable"
      class="table-base m-t-10"
      size="small"
      :data="tableData"
      border
      stripe
      height="400px"
    >
      <!-- <el-table-column fixed type="index" label="序号" align="center" width="55"> </el-table-column> -->
      <el-table-column prop="sceneName" label="积分类型" align="center" min-width="150"> </el-table-column>
      <el-table-column prop="points" label="收支明细" align="center" min-width="150" sortable>
        <template slot-scope="scope">
          <div>
            <span v-if="scope.row.type === 1" class="text-success">- {{ scope.row.points }}</span>
            <span v-else-if="scope.row.type === 0" class="text-danger">+ {{ scope.row.points }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="详细说明" align="center" min-width="150"> </el-table-column>

      <el-table-column prop="createdAt" label="充值时间" align="center" min-width="150" sortable>
        <template slot-scope="scope">
          {{ renderTimeFn(scope.row.createdAt) }}
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center m-t-10">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total,  prev, pager, next, jumper, sizes"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getPointLogList } from "@/api/points";
import tool from "@/utils/tool";
export default {
  name: "PointsTable",
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      tableData: [],
      loading: false
    };
  },
  mounted() {
    this.getPointLogListFn();
  },
  methods: {
    renderTimeFn(time) {
      return window.tool.getDateTime("yyyy-MM-dd hh:mm:ss", time);
    },
    getPointLogListFn() {
      const params = {
        page: this.page,
        pageSize: this.pageSize
      };
      this.loading = true;
      getPointLogList(params)
        .then(res => {
          this.tableData = res.rows || [];
          this.total = res.totalSize || 0;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;

          this.$message.error(err?.message);
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getCustomerCouponListFn();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getCustomerCouponListFn();
    }
  }
};
</script>

<style lang="less" scoped>
.points-table {
  box-sizing: border-box;
}
</style>
