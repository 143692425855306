<template>
  <table ref="table" class="table">
    <div ref="tableHead" class="flex table-thead flex-row-center">
      <tr class="flex1">
        <th class="flex flex-col-center w-30">
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"></el-checkbox>
        </th>
        <th v-for="(col, colIndex) in tableColumns" :key="colIndex" :style="setThStyle(col)">
          <div :class="['cell', col.sortable ? 'sort' : '']" @click="sortClick(col)">
            {{ col.label }}
            <div class="m-l-5 flex-column" v-if="col.sortable">
              <div class="sort-icon sort-up " :class="[col.prop == sortActive && sort == 'asc' ? 'active' : '']"></div>
              <div
                class="sort-icon sort-down "
                :class="[col.prop == sortActive && sort == 'desc' ? 'active' : '']"
              ></div>
            </div>
          </div>
        </th>
      </tr>
      <div class="min-w-170 p-tb-10 text-center font-weight-bold">操作</div>
    </div>

    <div ref="tableBody" class="flex table-tbody" v-on:scroll="scrollHandler">
      <el-checkbox-group class="flex1" v-model="selectionData" @change="handleCheckedChange" v-if="tableData.length">
        <div class="width-full" v-for="(item, index) in tableData" :key="index">
          <div class="flex m-t-10 p-tb-10 p-lr-10 bg-table-header">
            <el-checkbox :label="item"></el-checkbox>
            <slot name="tableHeader" :item="item" :index="index"></slot>
          </div>
          <div class="flex border-tb">
            <div class="flex1 flex-column border-right-1">
              <template v-if="item.details && item.details.length">
                <template v-for="(row, rowIndex) in item.details">
                  <tr class="flex1 table-body-tr" :key="rowIndex" v-if="rowIndex < 3 && !item.showMore">
                    <td v-for="(col, colIndex) in tableColumns" :key="colIndex" :style="setBodyTrStyle(col, colIndex)">
                      <div v-if="col.slot" class="cell">
                        <slot
                          name="cell"
                          :row="row"
                          :col="col"
                          :item="item"
                          :prop="col.prop"
                          :rowIndex="rowIndex"
                          :colIndex="colIndex"
                        ></slot>
                      </div>
                      <div v-else class="cell">
                        {{ row[col.prop] }}
                      </div>
                    </td>
                  </tr>
                  <tr class="flex1 table-body-tr" :key="rowIndex" v-if="item.showMore">
                    <td v-for="(col, colIndex) in tableColumns" :key="colIndex" :style="setBodyTrStyle(col, colIndex)">
                      <div v-if="col.slot" class="cell">
                        <slot
                          name="cell"
                          :row="row"
                          :col="col"
                          :item="item"
                          :prop="col.prop"
                          :rowIndex="rowIndex"
                          :colIndex="colIndex"
                        ></slot>
                      </div>
                      <div v-else class="cell">
                        {{ row[col.prop] }}
                      </div>
                    </td>
                  </tr>
                </template>
              </template>

              <!-- <template v-if="item.details && item.details.length">
                <tr class="flex1 table-body-tr" v-for="(row, rowIndex) in item.details" :key="rowIndex">
                  <td v-for="(col, colIndex) in tableColumns" :key="colIndex" :style="setBodyTrStyle(col, colIndex)">
                    <div v-if="col.slot" class="cell">
                      <slot
                        name="cell"
                        :row="row"
                        :col="col"
                        :item="item"
                        :prop="col.prop"
                        :rowIndex="rowIndex"
                        :colIndex="colIndex"
                      ></slot>
                    </div>
                    <div v-else class="cell">
                      {{ row[col.prop] }}
                    </div>
                  </td>
                </tr>
              </template> -->
              <div class="no-data" v-else>
                <span>暂无数据</span>
              </div>
            </div>
            <div class="min-w-150 p-tb-5 ">
              <slot name="controls" :item="item" :index="index"></slot>
            </div>
          </div>
          <div
            class="flex flex-center m-b-5 p-tb-5 text-primary cursor-pointer bg-table-header"
            v-if="item.details.length > 3"
            @click="item.showMore = !item.showMore"
          >
            <template v-if="!item.showMore">
              <span>展开查看更多商品</span>
              <i class="el-icon-caret-bottom font-size-20"></i>
            </template>
            <template v-else>
              <span>收起商品信息</span>
              <i class="el-icon-caret-top font-size-20"></i>
            </template>
          </div>
        </div>
      </el-checkbox-group>
      <div class="no-data" v-else>
        <span>暂无数据</span>
      </div>
    </div>
  </table>
</template>

<script>
const align = {
  left: "flex-start",
  center: "center",
  right: "flex-end"
};
export default {
  name: "TableList",
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      tableData: [
        // {
        //   id: 1,
        //   createdName: "王祥",
        //   createdAt: "2023-08-09 18:00:00",
        //   orderCode: "DD-20230831001",
        //   paymentType: "现付",
        //   statusName: "已完成",
        //   category: "1",
        //   totalQty: "1",
        //   totalPrice: "3000.00",
        //   details: [
        //     {
        //       code: "123",
        //       goodsName: "配件名称配件名称配件名称配件名称",
        //       brand: "品牌",
        //       productionPlace: "产地",
        //       price: "订货价",
        //       batchNo: "生产批号",
        //       qty: "订货数",
        //       deliverQty: "发货数量",
        //       termQty: "终止数量",
        //       receiveQty: "收货数量"
        //     },
        //     {
        //       code: "223",
        //       goodsName: "商品名称",
        //       brand: "品牌",
        //       productionPlace: "产地",
        //       price: "订货价",
        //       batchNo: "生产批号",
        //       qty: "订货数",
        //       deliverQty: "发货数量",
        //       termQty: "终止数量",
        //       receiveQty: "收货数量"
        //     }
        //   ]
        // }
      ],
      tableColumns: [
        // {
        //   label: "商品编码",
        //   prop: "code",
        //   width: "100px",
        //   align: "center",
        //   sortable: "custom"
        // },
        // {
        //   label: "商品名称",
        //   prop: "goodsName",
        //   width: "100px",
        //   align: "center",
        //   sortable: "custom",
        //   slot: true
        // },
        // {
        //   label: "品牌",
        //   prop: "brand",
        //   width: "100px",
        //   align: "center",
        //   sortable: "custom"
        // },
        // {
        //   label: "产地",
        //   prop: "productionPlace",
        //   width: "100px",
        //   align: "center",
        //   sortable: "custom",
        //   slot: true
        // },
        // {
        //   label: "订货价",
        //   prop: "price",
        //   align: "center",
        //   width: "100px",
        //   slot: true
        // },
        // {
        //   label: "仓库",
        //   prop: "warehouse",
        //   align: "center",
        //   width: "100px",
        //   slot: true
        // },
        // {
        //   label: "生产批号",
        //   prop: "batchNo",
        //   align: "center",
        //   width: "100px",
        //   slot: true
        // },
        // {
        //   label: "订货数",
        //   prop: "qty",
        //   width: "100px",
        //   align: "center",
        //   sortable: "custom",
        //   slot: true
        // },
        // {
        //   label: "发货数量",
        //   prop: "deliverQty",
        //   width: "100px",
        //   align: "center",
        //   sortable: "custom",
        //   slot: true
        // },
        // {
        //   label: "终止数量",
        //   prop: "termQty",
        //   width: "100px",
        //   align: "center",
        //   sortable: "custom",
        //   slot: true
        // },
        // {
        //   label: "收货数量",
        //   prop: "receiveQty",
        //   width: "100px",
        //   align: "center",
        //   sortable: "custom",
        //   slot: true
        // }
      ],
      selectionData: [],
      checkAll: false,
      isIndeterminate: false,
      sortActive: "",
      sort: ""
    };
  },
  watch: {
    data: {
      handler(val) {
        val.forEach(p => {
          p.showMore = false; // 展开查看更多商品
        });
        this.tableData = JSON.parse(JSON.stringify(val));
      },
      immediate: true
    },
    columns: {
      handler(val) {
        this.tableColumns = JSON.parse(JSON.stringify(val));
      },
      immediate: true
    }
  },
  mounted() {
    // window.addEventListener('')
  },
  methods: {
    //清空用户的选择
    clearSelection() {
      this.handleCheckAllChange(false);
    },
    checkedAll() {
      this.handleCheckAllChange(true);
    },
    scrollHandler(e) {
      this.$refs.tableHead.scrollLeft = e.target.scrollLeft;
    },
    sortClick(col) {
      if (!col.sortable) return;
      if (this.sortActive !== col.prop) {
        this.sortActive = col.prop;
        this.sort = "asc";
      } else {
        this.sort = this.sort == "asc" ? "desc" : "asc";
      }
      const sortStr = this.sortActive + "-" + this.sort;
      this.$emit("sort-change", sortStr);
    },
    handleCheckAllChange(val) {
      this.selectionData = val ? this.tableData : [];
      this.isIndeterminate = false;
      this.$emit("selection-change", this.selectionData);
    },
    handleCheckedChange(value) {
      const checkedCount = value.length;
      this.checkAll = checkedCount === this.tableData.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.tableData.length;
      this.$emit("selection-change", this.selectionData);
    },
    setThStyle(col) {
      return {
        flex: col.flex || 1,
        width: col.width ? col.width : "100px",
        minWidth: col.width ? col.width : "100px",
        justifyContent: align[col.align || "left"],
        textAlign: align[col.align || "left"]
      };
    },
    setBodyTrStyle(col, colIndex) {
      if (colIndex == 0) {
        return {
          flex: col.flex || 1,
          width: col.width ? parseInt(col.width) + 10 + "px" : "130px",
          minWidth: col.width ? parseInt(col.width) + 10 + "px" : "130px",
          marginLeft: "20px",
          justifyContent: align[col.align || "left"],
          textAlign: align[col.align || "left"]
        };
      }
      return {
        flex: col.flex || 1,
        width: col.width ? parseInt(col.width) + "px" : "100px",
        minWidth: col.width ? parseInt(col.width) + "px" : "100px",
        justifyContent: align[col.align || "left"],
        textAlign: align[col.align || "left"]
      };
    },
    handleSelectionChange(val) {
      this.selectionData = val;
    }
  }
};
</script>

<style lang="less" scoped>
@borderColor: #d9d9d9;
.table {
  height: 100%;
  width: 100%;
  display: flex;
  font-size: 14px;
  background-color: #ffffff;
  flex-direction: column;
  border: 1px solid @borderColor;
  overflow: hidden;
  .table-thead {
    display: flex;
    overflow-x: auto;
    font-weight: 500;
    background: #f0f0f0;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;

    tr {
      flex: 1;
      display: flex;
      th {
        padding: 10px 5px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        // border-right: 1px solid @borderColor;
        // border-bottom: 1px solid @borderColor;
      }
    }
  }
  .table-tbody {
    flex: 1;
    overflow: auto;
    border-right: 1px solid @borderColor;
    .table-body-tr,
    tr {
      display: flex;
      cursor: pointer;
      min-height: 40px;
      &:hover {
        background: #fafafa;
      }
      // 隔行变色
      &::nth-child(odd) {
        background: #ffffff;
      }
      &::nth-child(even) {
        background: #fafafa;
      }
      td {
        flex: 1;
        padding: 5px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        // border-right: 1px solid @borderColor;
      }
    }
    .table-body-tr + .table-body-tr {
      border-top: 1px solid @borderColor;
    }
  }
  .border-tb {
    border-top: 1px solid @borderColor;
    border-bottom: 1px solid @borderColor;
  }
  .min-w-170 {
    min-width: 170px;
  }
  .min-w-150 {
    min-width: 150px;
  }
  .cell {
    width: 100%;
  }
  /deep/.el-checkbox__label {
    display: none;
  }
  /deep/.el-checkbox-group {
    font-size: 14px;
  }
  .sort {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .sort-icon {
      display: inline-block;
      width: 10px;
      height: 5px;
    }
    .sort-up {
      background: #c0c4cc;
      clip-path: polygon(0 100%, 50% 0%, 100% 100%);
      &.active {
        background: #6589f8;
      }
    }
    .sort-down {
      margin-top: 2px;
      background: #c0c4cc;
      clip-path: polygon(0 0, 50% 100%, 100% 0%);
      &.active {
        background: #6589f8;
      }
    }
  }
  .no-data {
    width: 100%;
    height: 100%;
    color: #969799;
    font-size: 12px;
    line-height: 4em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
