import request from "@/utils/request";

/**
 * 积分日志列表
 */
export function getPointLogList(params) {
  return request({
    url: "/mall/points/points-list",
    methods: "get",
    params
  });
}

/**
 * 积分规则
 */
export function getPointsRules() {
  return request({
    url: "/mall/points/remark",
    method: "get"
  });
}

/**
 * 获取用户积分
 */
export function getPoints() {
  return request({
    url: "/mall/points/points",
    method: "get"
  });
}

/**
 * 获取可用积分，抵扣金额，赠送积分
 */
export function getPointsDeduct(data) {
  return request({
    url: "/mall/points/point-deduct",
    method: "post",
    data
  });
}
