<template>
  <div class="my-wallect flex-column height-full">
    <div class="bg-white p-a-15">
      <div class="flex flex-center font-size-20 font-weight-bold">
        <div class="flex-column flex-center">
          <div>积分</div>
          <div class="text-danger">{{ points }}</div>
        </div>
        <div class="m-l-30 flex-column flex-center">
          <div>优惠券</div>
          <div class="text-danger">{{ coupon }}张</div>
        </div>
      </div>
      <div class="text-right">
        <el-button @click="openPointsRule">积分规则</el-button>
        <el-button @click="toCouponCenter">领券中心</el-button>
      </div>
    </div>
    <div class="flex1 m-t-20 bg-white overflow-hidden">
      <el-tabs v-model="activeName">
        <el-tab-pane label="积分明细" name="points">
          <PointsTable />
        </el-tab-pane>
        <el-tab-pane label="优惠券" name="coupon">
          <CouponList />
        </el-tab-pane>
      </el-tabs>
    </div>
    <PointsRuleBox ref="pointsRuleBox" />
  </div>
</template>

<script>
import PointsRuleBox from "./components/PointsRuleBox.vue";
import PointsTable from "./components/PointsTable.vue";
import CouponList from "./components/CouponList.vue";
import { getCustomerCouponCount } from "@/api/coupon";
import { getPoints } from "@/api/points";

export default {
  name: "MyWallect",
  components: {
    PointsRuleBox,
    PointsTable,
    CouponList
  },
  data() {
    return {
      activeName: "points",
      points: 0,
      coupon: 0
    };
  },
  mounted() {
    this.getCustomerCouponCount();
    this.getPoints();
  },
  methods: {
    getCustomerCouponCount() {
      getCustomerCouponCount()
        .then(res => {
          this.coupon = res || 0;
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    },
    getPoints() {
      getPoints()
        .then(res => {
          this.points = res || 0;
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    },
    // 积分规则
    openPointsRule() {
      console.log("积分规则");
      this.$refs.pointsRuleBox.open();
    },
    // 领券中心
    toCouponCenter() {
      this.$router.push("/products/coupons-center");
    }
  }
};
</script>

<style lang="less" scoped>
.my-wallect {
  /deep/.el-tabs {
    height: 100%;
    .el-tabs__content {
      height: calc(100% - 55px);
      .el-tab-pane {
        height: 100%;
      }
    }
  }
  /deep/.el-tabs--top .el-tabs__item.is-top:nth-child(2),
  .el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
  .el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
  .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2) {
    padding: 0 20px;
  }
}
</style>
