<template>
  <el-dialog
    title="积分规则"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    width="600px"
    @close="close"
  >
    <div class="rule-content">
      {{ pointsRule }}
    </div>
  </el-dialog>
</template>

<script>
import { getPointsRules } from "@/api/points";

export default {
  name: "PointsRuleBox",
  data() {
    return {
      desc: "积分规则",
      pointsRule: "",
      visible: false
    };
  },
  methods: {
    open() {
      this.visible = true;
      this.getPointsRulesFn();
    },
    getPointsRulesFn() {
      getPointsRules()
        .then(res => {
          this.pointsRule = res || "";
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    },
    close() {
      this.visible = false;
    }
  }
};
</script>
<style lang="less" scoped>
.rule-content {
  white-space: pre-wrap;
}
</style>
