<template>
  <div class="client-page coupon-center flex-column p-a-10">
    <div class="flex flex-center font-size-24 p-t-10 p-b-20 border-b-1">
      <img :src="couponCenterIcon" class="h-35" />
      <span class="m-l-10 font-weight-bold color-red">领券中心</span>
    </div>
    <div class="flex1 overflow-auto m-t-10">
      <template v-for="(item, index) in couponList">
        <CouponItem :key="index" :couponInfo="item" :type="couponType" @clickBottom="pickCoupon">
          <template #btn>
            立即领取
          </template>
        </CouponItem>
      </template>
      <div v-if="!couponList.length" class="flex flex-center text-999 height-full">暂无数据</div>
    </div>
    <div class="text-center">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total,  prev, pager, next, jumper, sizes"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import CouponItem from "@/components/Coupon/CouponItem";
import { getCustomerCouponCanPickList, pickCustomerCoupon } from "@/api/coupon";

export default {
  name: "CouponsCenter", // 领券中心
  components: {
    CouponItem
  },
  data() {
    return {
      couponCenterIcon: require("@/assets/img/coupon/couponCenter.png"),
      page: 1,
      pageSize: 20,
      total: 0,
      couponList: [],
      couponType: "store"
    };
  },
  mounted() {
    this.getCouponList();
  },
  methods: {
    getCouponList() {
      const params = {
        page: this.page,
        pageSize: this.pageSize
      };
      getCustomerCouponCanPickList(params)
        .then(res => {
          this.couponList = res.rows || [];
          this.total = res.totalSize || 0;
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    },
    pickCoupon(data) {
      pickCustomerCoupon(data.id)
        .then(() => {
          this.$message.success("领取成功！");
          this.getCouponList();
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getCouponList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getCouponList();
    }
  }
};
</script>

<style lang="less" scoped>
.coupon-center {
  .color-red {
    color: #fe7448;
  }
  .border-b-1 {
    border-bottom: 2px dashed #fe7448;
  }
}
</style>
