<template>
  <el-dialog
    title="密码修改"
    :visible.sync="dialogVisible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    width="440px"
    @close="close"
  >
    <div class="content-box">
      <div class="m-b-10">
        <span class="color-error">为保障账号安全请设置新密码，修改后请牢记登录用户名与密码，您的登录用户名：</span>
        <span>{{ formData.account }}</span>
      </div>
      <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
        <el-form-item label="用户名：" prop="account">
          <el-input v-model="formData.account" disabled></el-input>
        </el-form-item>
        <el-form-item label="新密码：" prop="newPassword">
          <PassWord
            ref="newPassword"
            v-model="formData.newPassword"
            :password="formData.newPassword"
            :is-message="false"
          />
        </el-form-item>
        <el-form-item label="确认密码：" prop="confirmPassword">
          <PassWord v-model="formData.confirmPassword" :password="formData.confirmPassword" :is-message="false" />
        </el-form-item>
        <el-form-item label="绑定手机号：" prop="phone">
          <el-input v-model="formData.phone" maxlength="11" clearable placeholder="请输入需要绑定的手机号"></el-input>
        </el-form-item>
        <el-form-item label="手机验证码：" prop="account">
          <SMSCode
            v-model="formData.smsCaptcha"
            :smsCaptcha="formData.smsCaptcha"
            :phone="formData.phone"
            type="bindPhone"
          />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer">
      <el-button class="width-full" type="primary" @click="confirm">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import bus from "@/utils/bus";
import { mapState } from "vuex";
import PassWord from "@/components/PassWord";
import SMSCode from "@/components/SMSCode";
import { passwordValidate, passwordValidateTips } from "@/utils/validate";
import { updatePassword } from "@/api/base";

export default {
  name: "EditPassword",
  components: {
    PassWord,
    SMSCode
  },
  data() {
    const validator = (rule, value, callback) => {
      if (!passwordValidate(value)) {
        callback(new Error(passwordValidateTips));
      } else {
        if (rule.field === "confirmPassword") {
          if (value !== this.formData.newPassword) {
            callback(new Error("两次密码不匹配！"));
          } else {
            callback();
          }
        } else {
          callback();
        }
      }
    };
    return {
      desc: "密码修改",
      dialogVisible: false,
      formData: {
        phone: "",
        smsCaptcha: "",
        account: "",
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
      },
      rules: {
        newPassword: [{ validator: validator, trigger: "blur", required: true }],
        confirmPassword: [{ validator: validator, trigger: "blur", required: true }]
      }
    };
  },
  computed: {
    ...mapState("user", {
      userInfo: state => {
        return state.userInfo;
      }
    })
  },
  mounted() {
    bus.$on("force-reset-password", state => {
      if (state) {
        this.open();
      }
    });
  },
  methods: {
    open() {
      this.dialogVisible = true;
      setTimeout(() => {
        this.formData.account = this.userInfo.account;
        this.formData.oldPassword = this.formData.account;
      }, 200);
    },
    close() {
      this.dialogVisible = false;
    },
    confirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          updatePassword(this.formData)
            .then(() => {
              this.$message.success("修改成功");
              window.location.reload();
            })
            .catch(err => {
              this.$message.error(err.message || "修改失败");
            });
        }
      });
    }
  }
};
</script>
