<template>
  <div class="parts flex flex-column height-full">
    <div class="flex flex-row-center parts-search-form">
      <div class="parts-search">
        <el-input
          ref="PartsSearchInput"
          v-model="keyword"
          size="mini"
          clearable
          @keyup.enter.native="partsEnter"
          @focus="focusPartsSearchInput"
          placeholder="0键/Alt+0定位、Enter查询"
        >
          <div slot="append" class="parts-search-append">
            <div @click="partsSearch" class="parts-search-icon">
              <i class="el-icon-search"></i>
            </div>
            <div v-if="!noLoginLook && false" @click="partsSetting" class="parts-search-setting">
              <i class="el-icon-setting"></i>
            </div>
          </div>
        </el-input>
      </div>
      <div class="m-l-10">
        <el-button @click="advancedSetting" type="primary" size="mini">高级查询(Ins)</el-button>
      </div>
      <div class="m-l-20 flex flex-center">
        <span>促销：</span>
        <ht-select
          v-model="queryData.isPromotion"
          :options="promotionOptions"
          :clearable="false"
          size="mini"
          placeholder="促销"
          class="w-140"
        />
      </div>
      <div class="m-l-20 flex flex-center">
        <el-checkbox v-model="queryData.hasStock">有货配件(Alt+V)</el-checkbox>
      </div>
      <div class="m-l-20 flex flex-center">
        <el-checkbox v-model="queryData.isNormalBuy">常购配件(Alt+F)</el-checkbox>
      </div>
    </div>
    <div class="flex1 overflow-hidden">
      <ResizeBox is-column resize-box-name="PartsResizeBox" :block-one-proportion="0.6">
        <template v-slot:block1>
          <ResizeBox resize-box-name="PartsTopResizeBox" :block-one-proportion="0.7">
            <template v-slot:block1>
              <PartsListTable
                ref="PartsListTable"
                :queryData="queryData"
                @change="changeQueryData"
                @setSelectedRows="setPartsListTableSelectedRows"
                @hotkeysUp="handleKeyCode"
              />
            </template>
            <template v-slot:block2>
              <el-tabs class="parts-top-right p-a-5" v-model="partsTopRightActive">
                <el-tab-pane label="互换配件" name="one">
                  <PartsListExchangeTable
                    :queryData="queryData"
                    :rows="partsListTableSelectedRows"
                    @hotkeysUp="handleKeyCode"
                  />
                </el-tab-pane>
                <el-tab-pane label="适用车型" name="two">
                  <ApplicableVehicleType :rows="partsListTableSelectedRows" @hotkeysUp="handleKeyCode" />
                </el-tab-pane>
              </el-tabs>
            </template>
          </ResizeBox>
        </template>
        <template v-slot:block2>
          <ShoppingCartListTable ref="ShoppingCart" @hotkeysUp="handleKeyCode" @refreshList="refreshList" />
        </template>
      </ResizeBox>
    </div>
    <PartsKeyWordSearch :visible.sync="partsKeyWordSearchVisible" />
    <AdvancedSetting :visible.sync="advancedSettingVisible" @change="advancedQueryData" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ResizeBox from "@/components/ResizeBox";
import PartsKeyWordSearch from "./PartsKeyWordSearch";
import AdvancedSetting from "./AdvancedSetting";
import PartsListTable from "./PartsListTable";
import PartsListExchangeTable from "./PartsListExchangeTable";
import ApplicableVehicleType from "./ApplicableVehicleType";
import ShoppingCartListTable from "./ShoppingCartListTable";

export default {
  name: "ClientProductsParts",
  components: {
    ResizeBox,
    PartsKeyWordSearch,
    AdvancedSetting,
    PartsListTable,
    PartsListExchangeTable,
    ApplicableVehicleType,
    ShoppingCartListTable
  },
  data() {
    return {
      desc: "浏览商品-配件",
      keyScope: "PartsSearch",
      promotionOptions: [
        {
          id: 0,
          name: "全部"
        },
        {
          id: 1,
          name: "是"
        },
        {
          id: 2,
          name: "否"
        }
      ],
      partsKeyWordSearchVisible: false,
      advancedSettingVisible: false,
      partsTopRightActive: "one",
      keyword: "",
      queryData: {
        keyword: "", // 关键字
        // code: "", // 编码
        // codeMatch: "", // 编码匹配方式: 左匹配left/右匹配right/全匹配full/其他模糊
        // name: "", // 名称
        // nameMatch: "", // 名称匹配方式: 左匹配left/右匹配right/全匹配full/其他模糊
        // vehModel: "", // 车型
        // vehModelMatch: "", // 车型匹配方式: 左匹配left/右匹配right/全匹配full/其他模糊
        // brand: "", // 品牌
        // brandMatch: "", // 品牌匹配方式: 左匹配left/右匹配right/全匹配full/其他模糊
        // productionPlace: "", // 产地
        // productionPlaceMatch: "", // 产地匹配方式: 左匹配left/右匹配right/全匹配full/其他模糊
        // productionCode: "", // 生产码
        // productionCodeMatch: "", // 生产码匹配方式: 左匹配left/右匹配right/全匹配full/其他模糊
        // posCode: "", // 部位码
        // posCodeMatch: "", // 部位码匹配方式: 左匹配left/右匹配right/全匹配full/其他模糊
        // model: "", // 规格
        // modelMatch: "", // 规格匹配方式: 左匹配left/右匹配right/全匹配full/其他模糊
        // // 性质
        // properties: [],
        // // 仓库
        // warehouses: [],
        isPromotion: 0, // 促销: 0全部/1是/2否
        hasStock: true, // 是否只查有货, true是
        isNormalBuy: false, // 是否常购配件，true是
        categoryId: 0, // 配件分类ID，左侧选择的配件分类对应ID
        category: "", // 配件分类名称
        goodsId: 0, // 商品ID，在查询右侧互换件时传当前选择行的ID
        extaCode: "", // 在查询右侧互换件时原样传上来，左边查询时不传
        page: 0, // 页码
        pageSize: 0 // 页宽
      },
      partsListTableSelectedRows: []
    };
  },
  computed: {
    ...mapGetters("companyInfo", ["noLoginLook"])
  },
  mounted() {
    // 默认聚焦
    setTimeout(() => {
      this.$refs.PartsSearchInput && this.$refs.PartsSearchInput.focus();
    }, 300);
  },
  activated() {
    // 从我的钱包 优惠券 调过来的
    let couponId = this.$route.query.couponId;
    if (couponId) {
      this.queryData.couponId = Number(this.$route.query.couponId);
      setTimeout(() => {
        this.refreshList();
      }, 200);
    }

    setTimeout(() => {
      this.$hotkeys("*", this.keyScope, window.tool.debounceFn(this.handleKeyCode, 300));
      this.$hotkeys.setScope(this.keyScope);
      // 缓存后进入到此页面后聚焦到 partsListTable
      this.$refs.PartsListTable && this.$refs.PartsListTable.$refs.partsListTable.$el.click();
    }, 500);
  },
  deactivated() {
    this.$hotkeys.unbind("*", this.keyScope);
  },
  methods: {
    handleKeyCode(e) {
      switch (e.code) {
        case "Insert":
          e.preventDefault();
          e.stopPropagation();
          this.advancedSetting();
          break;
        case "Digit0":
        case "Numpad0":
          e.preventDefault();
          e.stopPropagation();
          setTimeout(() => {
            this.$refs.PartsSearchInput.focus();
          }, 0);
          break;
        case "KeyF":
          e.preventDefault();
          e.stopPropagation();
          if (e.altKey) {
            this.queryData.isNormalBuy = !this.queryData.isNormalBuy;
          }
          break;
        case "KeyV":
          e.preventDefault();
          e.stopPropagation();
          if (e.altKey) {
            this.queryData.hasStock = !this.queryData.hasStock;
          }
          break;
        case "F3":
        case "F5":
        case "F6":
        case "F7":
        case "F11":
        case "KeyA":
        case "KeyB":
          e.preventDefault();
          e.stopPropagation();
          if (this.$hotkeys.getScope() !== "shoppingCartListTable") {
            this.$refs.ShoppingCart.hotkeysUp(e);
          }
          break;
      }
    },
    partsSearch() {
      this.setKeyword();
    },
    partsSetting() {
      this.partsKeyWordSearchVisible = true;
    },
    advancedSetting() {
      this.advancedSettingVisible = true;
    },
    focusPartsSearchInput(e) {
      e.target.select();
    },
    partsEnter() {
      this.setKeyword();
    },
    setKeyword() {
      if (!this.keyword || this.keyword.length < 2) {
        this.$message.error("关键字至少输入两位！");
        return;
      }
      this.changeQueryData();
      this.$refs.PartsSearchInput.blur();
    },
    changeQueryData(data) {
      this.queryData = {
        keyword: this.keyword, // 关键字
        isPromotion: this.queryData.isPromotion ? this.queryData.isPromotion : 0, // 促销: 0全部/1是/2否
        hasStock: this.queryData.hasStock || false, // 是否只查有货, true是
        isNormalBuy: this.queryData.isNormalBuy || false,
        category: data ? data.category : this.queryData.category || ""
      };
      if (data?.id === 0) {
        //未分类
        this.queryData.noCategory = true;
      } else {
        this.queryData.noCategory = false;
      }
      setTimeout(() => {
        this.refreshList();
      }, 200);
    },
    advancedQueryData(data) {
      this.queryData = {
        ...data,
        isPromotion: 0, // 促销: 0全部/1是/2否
        hasStock: this.queryData.hasStock, // 是否只查有货, true是
        isNormalBuy: false,
        resetCategory: true
      };
      this.keyword = "";
    },
    setPartsListTableSelectedRows(rows) {
      this.partsListTableSelectedRows = rows;
    },
    // 购物车列表发送的刷新列表事件
    refreshList() {
      this.$refs.PartsListTable.pageChange(1);
    }
  }
};
</script>

<style lang="scss" scoped>
.parts {
  .parts-search-form {
    padding: 8px;
    box-sizing: border-box;
    background-color: #f2f2f2;
  }
  /deep/ .parts-search {
    width: 250px;
    .el-input-group__append {
      padding: 0;
    }
    .parts-search-append {
      display: flex;
      .parts-search-icon,
      .parts-search-setting {
        padding: 0 10px;
        cursor: pointer;
        font-size: 18px;
      }
      .parts-search-setting {
        border-left: 1px solid #dcdfe6;
      }
    }
  }
  /deep/ .parts-top-right {
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    box-sizing: border-box;
    .el-tabs__nav-wrap::after {
      height: 0;
    }
    .el-tabs__header {
      border: 1px solid #e4e4e4;
      border-bottom: 0;
      margin-bottom: 0;
      background-color: #dbdbdb;
    }
    .el-tabs__nav {
      display: flex;
      .el-tabs__item {
        height: 28px;
        line-height: 28px;
        text-align: center;
      }
    }
    .el-tabs__content {
      flex: 1;
      overflow: auto;
      padding: 0 !important;
      .el-tab-pane {
        height: 100%;
      }
    }
  }
}
</style>
