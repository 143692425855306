export default [
  {
    path: "/products",
    name: "浏览商品",
    redirect: "/products/browse-products",
    isSingle: true,
    component: {
      render(h) {
        return h("router-view");
      }
    },
    icon: "el-icon-s-goods",
    children: [
      {
        path: "/products/browse-products",
        name: "商品列表",
        meta: {
          keepAlive: true
        },
        component: () => import("@/views/products/index.vue")
      },
      {
        path: "/products/details",
        name: "商品详情",
        isHide: true,
        component: () => import("@/views/productsDetails/index.vue")
      },
      {
        path: "/products/shopping-cart",
        name: "购物车",
        isHide: true,
        component: () => import("@/views/shoppingCart/index.vue")
      },
      {
        path: "/products/coupons-center",
        name: "领券中心",
        isHide: true,
        component: () => import("@/views/couponsCenter/index.vue")
      }
    ]
  },
  {
    path: "/order",
    name: "我的订单",
    redirect: "/order/order-list",
    component: {
      render(h) {
        return h("router-view");
      }
    },
    icon: "el-icon-s-order",
    children: [
      {
        path: "/order/order-list",
        name: "订单列表",
        meta: {
          keepAlive: true
        },
        component: () => import("@/views/order/orderList/index.vue")
      },
      {
        path: "/order/order-list/order-detail",
        name: "订单详情",
        isHide: true,
        component: () => import("@/views/order/orderDetail/index.vue")
      },
      {
        path: "/order/order-list/modify-order-detail",
        name: "订单详情", //(待提交)修改订单详情
        isHide: true,
        component: () => import("@/views/order/orderDetail/modifyOrderDetail.vue")
      },
      {
        path: "/order/order-list/modify-order-detail1",
        name: "订单详情", //(待发货/待确认)修改订单详情
        isHide: true,
        component: () => import("@/views/order/orderDetail/modifyOrderDetail1.vue")
      },
      {
        path: "/order/order-list/order-pay",
        name: "订单支付",
        isHide: true,
        component: () => import("@/views/order/orderPay/index.vue")
      },
      {
        path: "/order/after-sale",
        name: "退换/售后",
        isHide: true
      },
      {
        path: "/order/index3",
        name: "出库查询",
        isHide: true
      },
      {
        path: "/order/public",
        name: "公共路径",
        isHide: true,
        component: () => import("@/views/public.vue")
      }
    ]
  },
  // {
  //   path: "/reconciliations",
  //   name: "对账中心",
  //   redirect: "/reconciliations/currentReconciliation",
  //   component: {
  //     render(h) {
  //       return h("router-view");
  //     }
  //   },
  //   icon: "el-icon-s-claim",
  //   isHide: true,
  //   children: [
  //     {
  //       path: "/reconciliations/currentReconciliation",
  //       name: "往来对账",
  //       component: () => import("@/views/reconciliations/currentReconciliation/index.vue")
  //     },
  //     {
  //       path: "/reconciliations/statementDetails",
  //       name: "对账单详情",
  //       isHide: true,
  //       component: () => import("@/views/reconciliations/statementDetails/index.vue")
  //     },
  //     {
  //       path: "/reconciliations/statementPay",
  //       name: "对账单支付",
  //       isHide: true,
  //       component: () => import("@/views/reconciliations/statementPay/index.vue")
  //     },
  //     {
  //       path: "/reconciliations/logisticsReconciliation",
  //       name: "物流代收对账",
  //       isHide: true
  //     },
  //     {
  //       path: "/reconciliations/index4",
  //       name: "开票管理",
  //       isHide: true
  //     }
  //   ]
  // },
  {
    path: "/manage",
    name: "管理中心",
    redirect: "/manage/personal-data",
    component: {
      render(h) {
        return h("router-view");
      }
    },
    icon: "el-icon-s-custom",
    children: [
      {
        path: "/manage/personal-data",
        name: "个人资料",
        component: () => import("@/views/manage/personalData/index.vue")
      },
      {
        path: "/manage/company-data",
        name: "企业资料",
        component: () => import("@/views/manage/companyData/index.vue")
      },
      {
        path: "/manage/my-wallet",
        name: "我的钱包",
        component: () => import("@/views/manage/myWallet/index.vue")
      },
      {
        path: "/manage/staff",
        name: "员工管理",
        isHide: true,
        component: () => import("@/views/manage/staff/index.vue")
      },
      {
        path: "/manage/address",
        name: "地址管理",
        component: () => import("@/views/manage/address/index.vue")
      },
      {
        path: "/manage/associated",
        name: "关联思锐ERP",
        component: () => import("@/views/manage/associated/index.vue")
      }
    ]
  }
];
